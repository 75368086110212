import React, { useEffect } from "react";
// import Mu from "../resources/images/Mu.png"
// import ConnectingButtons from "./ConnectingButtons";
import { connect } from "react-redux";
import { setIsRoomHost } from "../store/actions";
import "./IntroductionPage.css";

const IntroductionPage = ({ setIsRoomHostAction }) => {
  useEffect(() => {
    setIsRoomHostAction(false);
    // eslint-disable-next-line
  }, []);

  return (
    // <div className="introduction_page_container">
    //   <div className="introduction_page_panel">
    //     <img src={Mu} className="introduction_page_image" alt="img"></img>
    //     <ConnectingButtons />
    //   </div>
    // </div>

    // <div className="introductionPageContainer">
    //   <div className="userVideo">
    //     <div className="userVideoHeader">
    //       <img src="../assets/icons/micIcon.svg" alt="" className="videoUsername" />
    //     </div>
    //     <img
    //       className="userVideoBox"
    //       src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" />
    //     <div className="userVideoFooter">
    //       <p className="videoUsername">Diana</p>
    //       <img className="videoBtn" src="../assets/icons/mic-small.svg" alt="" />
    //       <img className="videoBtn" src="../assets/icons/video-small.svg" alt="" />
    //     </div>
    //   </div>
    //   <div className="introductionPageContentBox">
    //     <h2 className="heading">Host Meeting</h2>
    //     <div className="formGroup">
    //       <label htmlFor="name" className="formLabel">Name</label>
    //       <input type="text" className="formInput" placeholder="Enter your Name" />
    //     </div>
    //     <Checkbox label={'Only audio'}/>
    //     <div className="primaryBtn">Join Meeting</div>
    //   </div>
    // </div>

    <>
   <div className="getperouter">
    <div className="cssloader" >
      <img src="/assets/img/masterLoader.gif" alt="" />
      </div>
    </div>
    <div className="container">
    <div className="footerContainer">
        <div className="logo">
            <img src="/assets/icons/logo.svg" alt="" />
        </div>
    </div>
    <p className="footerText">Page Not Found Error (404). Please check your url or link</p>
    <p className="footerText">For information about data privacy, security or other terms & agreement please view our website.</p>
    <p className="footerText">@ 2023 Getpepped.in</p>
   
    </div>
    </>
  );
};

const mapActionsToProps = (dispatch) => {
  return {
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
  };
};

export default connect(null, mapActionsToProps)(IntroductionPage);
