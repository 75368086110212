import React from "react";
import {completeInterview, completeInterview2} from "../../utils/api";

const LeaveRoomButton = ({hostLeave, isRoomHost, hostId, meetEnd, isSas, guestLink, hostLink,userId}) => {
  const handleRoomDisconnection = () => {
    // const siteUrl = window.location.origin;
    // window.location.href = siteUrl;
   
  if(isRoomHost && isSas){
    completeInterview(hostId);
    const obj = {
      roomId: hostId,
      userId: userId
    }
    completeInterview2(obj)
      const siteUrl = `${hostLink}`
      window.location.href = siteUrl;
      // console.log(hostLink);
  } else if(isRoomHost && !isSas){
    completeInterview(hostId);
      const siteUrl = `https://mentor.getprepped.in/createFeedback?id=${hostId}`
      window.location.href = siteUrl;
  } else if(!isRoomHost && isSas){
    const siteUrl = `${guestLink}`
      window.location.href = siteUrl;
      // console.log(guestLink);
  }else{
    const siteUrl = `https://mentee.getprepped.in/createFeedback?id=${hostId}`
    // window.location.href = siteUrl;
  }
    

    // if(isRoomHost){
    //   completeInterview(hostId);
    //   const siteUrl = `https://mentor.getprepped.in/createFeedback?id=${hostId}`
    //   window.location.href = siteUrl;
    // }else{
    //   // const siteUrl = `http://localhost:3000/join-room?`
    //   const siteUrl = `https://mentee.getprepped.in/createFeedback?id=${hostId}`

    //   window.location.href = siteUrl;
    // }
  };

  if(hostLeave || meetEnd){
    handleRoomDisconnection();
  }

  return (
    <button className="dangerBtn" onClick={handleRoomDisconnection}>Leave Meeting</button>
  );
};

export default LeaveRoomButton;



