import React from "react";
const Overlay = () => {
  return (
    <div className="overlay_container">
    <div className="cssloader" >
      <img src="/assets/img/masterLoader.gif" alt="" />
      </div>
    </div>
  );
};

export default Overlay;
