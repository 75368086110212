import React, { useEffect, useState } from "react";
import JoinRoomInputs from "./JoinRoomInputs";
import { connect } from "react-redux";
// import OnlyWithAudioCheckbox from "./OnlyWithAudioCheckbox";
import {
  setConnectOnlyWithAudio,
  setIdentity,
  setRoomId,
} from "../store/actions";
import ErrorMessage from "./ErrorMessage";
import JoinRoomButtons from "./JoinRoomButtons";
import { useHistory } from "react-router-dom";
import { getRoomExists } from "../utils/api";
import { useLocation } from "react-router-dom";
import { getMeetingStatus, updateMentorJoiningStatus, onUserJoined, startInterview } from "../utils/api";
import moment from "moment/moment";
import { useRef } from "react";


const JoinRoomContent = (props) => {
  const {
    isRoomHost,
    isSas,
    // setConnectOnlyWithAudio,
    // connectOnlyWithAudio,
    setIdentityAction,
    setRoomIdAction,
  } = props;

  const [roomIdValue, setRoomIdValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [msgColour,setMsgColour]=useState("")
  const [status,setStatus] = useState("")

  const history = useHistory();
  const { search } = useLocation();
  const ref = useRef;

  const query = new URLSearchParams((search), [search]);
  const id = query.get("id")
  const name = query.get("name")
  const userId = query.get("userId")

  const handleJoinRoom = async () => {
    setIdentityAction(name ? name : nameValue);
    if (isRoomHost) {

      await createRoom();

    } else {
      await joinRoom();
    }
  }; 

  useEffect(() => {
    if(id){
      setRoomIdValue(id)
    }
  },[id])

  const joinRoom = async () => {
    const responseMessage = await getRoomExists(roomIdValue);

    const { roomExists, full } = responseMessage;

    if (roomExists) {
      if (full) {
        setErrorMessage("Meeting is full. Please try again later.");
      } else {
        // join a room !
        setRoomIdAction(roomIdValue);
        history.push("/room");
      }
    } else {
      setErrorMessage("Please wait for the Host to start the meeting" );
      setMsgColour("newErrorMessage")
       let aj = setInterval(async() => {
       const data= await getRoomExists(id)
       setStatus(data.roomExists);
      }, 15000);

    ref.current = aj

  };

  };

  useEffect(() => {
    if(status) {
      clearInterval(ref.current)
      console.log("useRefff", ref);
      console.log("host join the room");
        setErrorMessage(" Host has joined the meeting, please join now !");
        setMsgColour("newErrorMessage textBlue")
    } 
  },[status])


  const createRoom = async() => {
    if(isSas){
      console.log("abel to join", isSas);
      history.push("/room");
    }else{
      window.location.reload()
    }

  };

  return (
    <>
      <JoinRoomInputs
        id={id}
        name={name}
        roomIdValue={roomIdValue}
        setRoomIdValue={setRoomIdValue}
        nameValue={nameValue}
        setNameValue={setNameValue}
        isRoomHost={isRoomHost}
      />
      {/* <OnlyWithAudioCheckbox
        setConnectOnlyWithAudio={setConnectOnlyWithAudio}
        connectOnlyWithAudio={connectOnlyWithAudio}
      /> */}
      <ErrorMessage className={msgColour} errorMessage={errorMessage} />
      <JoinRoomButtons
        ref={ref}
        handleJoinRoom={handleJoinRoom}
        isRoomHost={isRoomHost}
        name={name ? name : nameValue}
      />
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setConnectOnlyWithAudio: (onlyWithAudio) =>
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (roomId) => dispatch(setRoomId(roomId)),
  };
};

export default connect(
  mapStoreStateToProps,
  mapActionsToProps
)(JoinRoomContent);
