import React, { useEffect, useId, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setIsRoomHost, setHostId, setHostLink, setGuestLink, setIsSas, setUserId } from "../store/actions";
import JoinRoomTitle from "./JoinRoomTitle";
import JoinRoomContent from "./JoinRoomContent";
// import Checkbox from "../components/globalComponents/checkbox/Checkbox";
import * as webRTCHandler from "../utils/webRTCHandler";
import { getMeetingInfo } from "../utils/api";

import "./JoinRoomPage.css";
import Overlay from "../RoomPage/Overlay";

const JoinRoomPage = (props) => {
  const {
    setIsRoomHostAction,
    isRoomHost,
    setHostIdAction,
    connectOnlyWithAudio,
    showOverlay,
    setIsSasAction,
    setHostLinkAction,
    setGuestLinkAction,
    setUserIdAction,
    hostLink,
    guestLink
    // frontCam
  } = props;
 
//  useEffect(()=>{
//   console.log(frontCam,"Effect6545465");
//  },[frontCam])
  const search = useLocation().search;
  // const id = search.slice(14);
  const query = new URLSearchParams((search), [search]);
  const id = query.get("id")
  const userId = query.get("userId");
 
// api call for the dataBase 
 const [infoData, setInfoData]=  useState("");
 const [showOverlay3,setShowOverlay3] = useState(true);
 const getData = async()=>{
  const obj = {
    id:id,
    userId:userId
  }
  
  const data= await getMeetingInfo(obj)
  setInfoData(data)
}
console.log("daddadaata",infoData.IsSuccess);
  useEffect(() => {
    getData();
 
    if (!connectOnlyWithAudio) {
      webRTCHandler.GetFrontPageStreamInitFrontPage();
    }
    
  }, []);

  useEffect(() => {
    if(infoData.IsSuccess){
      setShowOverlay3(false)
    }
    console.log("host", infoData?.Data?.Users[0]?.isHost);
    if (infoData?.Data?.Users[0]?.isHost && infoData?.Data?.Users[0]?.isSas && infoData?.IsSuccess && id && userId ) {
      console.log("Run the loop", );
      setIsRoomHostAction(true);
      setHostIdAction(id);
      setUserIdAction(userId);
      setIsSasAction(true)
      setHostLinkAction(infoData?.Data?.Users[0]?.redirectLink) 
    }


    if(infoData?.Data?.Users[0]?.isSas && !infoData?.Data?.Users[0]?.isHost){
      setIsSasAction(true)
      setGuestLinkAction(infoData?.Data?.Users[0]?.redirectLink)
      console.log("hello");
    }

    
  },[infoData])
  
  console.log("hostLink",hostLink);
  console.log("guestLink",guestLink);
     

  return (
    <>
    {showOverlay && <Overlay/>}
    {showOverlay3 && <Overlay/>}
      <div className="introductionPageContainer">
        <div className="introductionPageContentBox">
          <JoinRoomTitle isRoomHost={isRoomHost} />
          <JoinRoomContent />
        </div>
      </div>
    </>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
    setHostIdAction: (hostId) => dispatch(setHostId(hostId)),
    setIsSasAction:(isSas)=>dispatch(setIsSas(isSas)),
    setHostLinkAction:(hostLink)=>dispatch(setHostLink(hostLink)),
    setGuestLinkAction:(guestLink)=>dispatch(setGuestLink(guestLink)),
    setUserIdAction:(userId)=>dispatch(setUserId(userId)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(JoinRoomPage);
