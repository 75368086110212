export const environment ={

// BACKEND:"https://video-meet2022.herokuapp.com/api",
// SERVER : "https://video-meet2022.herokuapp.com/",

// BACKEND:"http://localhost:2023/api",
// SERVER:"http://localhost:2023/"

// BACKEND:"https://video-meet2022.herokuapp.com/api",
// SERVER:"https://video-meet2022.herokuapp.com/"

BACKEND:"https://mu-meet-api.mastersunion.org/api",
SERVER:"https://mu-meet-api.mastersunion.org/"

}



