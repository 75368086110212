import React from "react";
import { connect } from "react-redux";
import { setActiveConversation } from "../../store/actions";
import Profile from "../../resources/images/listSvg.svg"


const SingleParticipant = (props) => {
  const {
    identity,
    // lastItem,
    // participant,
    // setActiveConversationAction,
    // socketId,
    participants,
    index,
  } = props;

  // const handleOpenActiveChatbox = () => {
  //   if (participant.socketId !== socketId) {
  //     setActiveConversationAction(participant);
  //   }
  // };

  return (
    // <>
    //   <p className="participants_paragraph" onClick={handleOpenActiveChatbox}>
    //  <span>   <img
    //       src={Profile}
    //       alt="img"
    //     /></span>

    //     {identity}
    //   </p>
    //   {!lastItem && <span className="participants_separator_line"></span>}
    // </>
    <div className="member">
      <div className="memberDetail">
        <img
          // src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          src={Profile}
          alt=""
          className="memberImg"
        />
        <div className="memberDetailsRight">
          <p className="memberName">{identity}</p>
          {/* <p className="memberPosition">Project Manager</p> */}
        </div>
      </div>
      <div className="memberRight">
        {participants[index].Host && <div className="primaryTag">Mentor</div>}
        {/* <img src="../assets/icons/micOff.svg" alt="" />
                            <img src="../assets/icons/VideoOn.svg" alt="" /> */}
      </div>
    </div>
  );
};

const Participants = ({
  participants,
  setActiveConversationAction,
  socketId,
}) => {
  return (
    <div className="membersList">
      {participants.map((participant, index) => {
        return (
          <SingleParticipant
            key={participant.identity}
            participants={participants}
            participant={participant}
            identity={participant.identity}
            setActiveConversationAction={setActiveConversationAction}
            socketId={socketId}
            index={index}
          />
        );
      })}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setActiveConversationAction: (activeConversation) =>
      dispatch(setActiveConversation(activeConversation)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Participants);
