import axios from "axios";
import {environment} from "../environment/environment"

// const serverApi = "https://video-meeting-backend2022.herokuapp.com/api";

export const getRoomExists = async (roomId) => {
  const response = await axios.get(`${environment.BACKEND}/room-exists/${roomId}`);
  return response.data;
};

export const getTURNCredentials = async () => {
  const response = await axios.get(`${environment.BACKEND}/get-turn-credentials`);
  return response.data;
};

export const completeInterview = async (bookingId) => {
  const response = await axios.post(
    "https://apireact.mastersunion.org/api/getPrepped/admin/booking/" +
    bookingId +
    "/completeInterview",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }
  );
  return response;
}

export const startInterview = async ({roomId, userId}) => {
  const response = await axios.post(
    "https://api.getprepped.in/api/" +
    roomId +
    "/"+ userId + "/startInterview",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }
  );
  return response;
}

export const completeInterview2 = async ({roomId, userId}) => {
  const response = await axios.post(
    "https://api.getprepped.in/api/" +
    roomId +
    "/"+ userId + "/completeInterview",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }
  );
  return response;
}

export const getMeetingStatus = async (id) => {
  const response = await axios.get(
    "https://apireact.mastersunion.org/api/getPrepped/mentee/meeting/" +
    id +
    "/getMeetingStatus",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }
  );
  return response.data;
}


export const updateMentorJoiningStatus = async (obj) => {
  const object = {
    status: obj.status
  }
  const response = await fetch(
    "https://apireact.mastersunion.org/api/getPrepped/admin/meeting/" +
    obj.id + "/updateMentorJoiningStatus",
    {
      method: "PUT",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(object)
    }
  );
  return response.data;
}

export const getMeetingInfo = async ({id, userId}) => {
  const response = await axios.get(
    `${environment.BACKEND}/getMeetingInfo/` + id + "/"+ userId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }
  );
  return response.data;
}

export const onUserJoined =async({id,name,time})=>{
  const response = await axios.post(
    `${environment.BACKEND}/onUserJoined/`+id+"/"+name+"/"+time,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }
  );
  return response.data; 
}

// export const onUserEnd =async({id,name,time})=>{
//   const response = await axios.post(
//     "http://localhost:2023/api/onUserEnd/"+id+"/"+name+"/"+time,
//     {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json"
//       },
//     }
//   );
//   return response.data; 
// }

