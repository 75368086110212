import React from "react";
import "./JoinRoomPage.css";

const Input = ({ placeholder, value, changeHandler }) => {
  return (
    <input
      value={value}
      onChange={changeHandler}
      className="join_room_input"
      placeholder={placeholder}
    />
  );
};

const JoinRoomInputs = (props) => {
  

  const { roomIdValue, setRoomIdValue, nameValue, setNameValue, isRoomHost, id, name } =
    props;
   
    // console.log(id);
  const handleRoomIdValueChange = (event) => {
    id ? setRoomIdValue(id) : setRoomIdValue(event.target.value);
  };

  const handleNameValueChange = (event) => {
    setNameValue(event.target.value);
  };

  return (
    <div className="formGroup">
      <label htmlFor="name" className="formLabel">Name</label>
      {!isRoomHost && id === "" && (
        <Input
          className="formInput"
          placeholder="Enter meeting ID"
          value={roomIdValue}
          changeHandler={handleRoomIdValueChange}
        />
      )}
      <Input
       className="formInput"
        placeholder="Enter your Name"
        value={name ? name : nameValue}
        changeHandler={handleNameValueChange}
      />
    </div>
  );
};

export default JoinRoomInputs;
