import Actions from "./actions";

const initState = {
  identity: "",
  isRoomHost: false,
  connectOnlyWithAudio: false,
  roomId: null,
  showOverlay: true,
  showOverlay2: true,
  participants: [],
  messages: [],
  activeConversation: null,
  directChatHistory: [],
  socketId: null,
  valid: true,
  showChat: false,
  showParticipants: false,
  hostId: null,
  shareBtn: false,
  mikeInfo: true,
  hostLeave: false,
  meetEnd: false,
  frontMic: false,
  frontCam: false,
  stable:false,
  hostLink: "",
  guestLink: "",
  isSas:false,
  userId:""
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.SET_IS_ROOM_HOST:
      return {
        ...state,
        isRoomHost: action.isRoomHost,
      };
    case Actions.SET_CONNECT_ONLY_WITH_AUDIO:
      return {
        ...state,
        connectOnlyWithAudio: action.onlyWithAudio,
      };
    case Actions.SET_ROOM_ID:
      return {
        
        ...state,
        roomId: action.roomId,
      };
    case Actions.SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      };
    case Actions.SET_SHOW_OVERLAY:
      return {
        ...state,
        showOverlay: action.showOverlay,
      };
    case Actions.SET_SHOW_OVERLAY2:
      return {
        ...state,
        showOverlay2: action.showOverlay2,
      }
    case 'SET_VALID':
      return {
        ...state,
        valid: action.text,
      };
    case Actions.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.participants,
      };
    case Actions.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case Actions.SET_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversation: action.activeConversation
      }
    case Actions.SET_DIRECT_CHAT_HISTORY:
      return {
        ...state,
        directChatHistory: action.directChatHistory
      };
    case Actions.SET_SOCKET_ID:
      return {
        ...state,
        socketId: action.socketId
      }
    case Actions.SET_SHOWCHAT:
      return {
        ...state,
        showChat: action.showChat
      }
    case Actions.SET_SHOWPARTICIPANTS:
      return {
        ...state,
        showParticipants: action.showParticipants
      }
    case Actions.SET_HOST_ID:
      return {
        ...state,
        hostId: action.hostId
      }
    case Actions.SET_SHAREBTN:
      return{
        ...state,
        shareBtn: action.shareBtn
      }
    case Actions.SET_MIKEINFO:
      return{
        ...state,
        mikeInfo:action.mikeInfo
      } 
    case Actions.SET_HOSTLEAVE:
      return{
        ...state,
        hostLeave:action.hostLeave
      }
    case Actions.SET_MEETEND: 
      return{
        ...state,
        meetEnd:action.meetEnd
      }
    case Actions.SET_FRONTMIC:
       return{
        ...state,
        frontMic:action.frontMic
       }
    case Actions.SET_FRONTCAM:
        return{
         ...state,
         frontCam:action.frontCam
        } 
    case Actions.SET_STABLE:
        return{
          ...state,
          stable:action.stable
        }
    case Actions.SET_HOSTLINK:
        return{
          ...state,
          hostLink:action.hostLink
        }
     case Actions.SET_GUESTLINK:
        return{
          ...state,
          guestLink:action.guestLink
        }
      case Actions.SET_ISSAS:
        return{
          ...state,
          isSas:action.isSas
        } 
        case Actions.SET_USERID:
          return{
            ...state,
            userId:action.userId
          }                                
    default:
      return state;
  }
};

export default reducer;
