import { setShowOverlay, setMessages,setValid, setShareBtn, setMikeInfo, setShowOverlay2,setFrontCam,setFrontMic, setStable} from "../store/actions";
import store from "../store/store";
import * as wss from "./wss";
import Peer from "simple-peer";
import { fetchTURNCredentials, getTurnIceServers } from "./turn";
import CamOn from "../resources/images/video-small.svg";
import CamOf from "../resources/images/videoStop.svg";
import MicOn from "../resources/images/mic-small.svg";
import MicOf from "../resources/images/micOff.svg";

const defaultConstraints = {
  audio: true,
  video: {
    width: "480",
    height: "360",
  },
};


const onlyAudioConstraints = {
  audio: true,
  video: false,
};
/////////////////////////////////////////////////////////////////////////////////////////////
let frontPageStream;

export const GetFrontPageStreamInitFrontPage=()=>{
 const constraints =  defaultConstraints;
 navigator.mediaDevices
   .getUserMedia(constraints)
   .then((stream)=>{
    // stopBothVideoAndAudio(localStream)
    console.log("successfully recived front page stream");
    frontPageStream= stream;
    showFrontVideoPreview(frontPageStream)
    store.dispatch(setShowOverlay(false));
   }).catch((err) => {
    console.log(err)
    console.log(err);
    // store.dispatch(setValid(false));
    // stopBothVideoAndAudio();
    
    if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
      //required track is missing 
      alert("Mic and camera Not found")
      window.location.reload()

  } else if (err.name === "NotReadableError" || err.name === "TrackStartError") {
      //webcam or mic are already in use 
      alert("Mic and camera already in use")
      window.location.reload()

  } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
      //constraints can not be satisfied by avb. devices 
      alert("System is not Compactable")
      window.location.reload()

  } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
      //permission denied in browser 
      alert(" Access denied for using the Camera and microphone")
      window.location.reload()

  } else if (err.name === "TypeError" || err.name === "TypeError") {
      //empty constraints object 
  } else {
      //other errors
  }
   });


}

// export const stopBothVideoAndAudio=(stream)=> {
//   stream.getTracks().forEach(function(track) {
//       if (track.readyState == 'live') {
//           track.stop();
//       }
//   });
//   alert("please give camra and mic acess") 
//   window.location.reload()
// }
const showFrontVideoPreview = (stream) => {
  const videosContainer = document.querySelector(".introductionPageContainer");
  const videoContainerInner = document.createElement("div");
  videoContainerInner.classList.add("userVideo");
  const videoElement = document.createElement("video");
  videoElement.classList.add("Frontvideo")
  videoElement.autoplay = true;
  videoElement.muted = true;
  videoElement.srcObject = stream;

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };
 
  const frontFooter = document.createElement("div");
  frontFooter.classList.add("userVideoFooter");
  const videoBtn = document.createElement("img");
  const micBtn = document.createElement("img");
 
  videoBtn.setAttribute("src",CamOn);
  videoBtn.setAttribute("src",CamOn);
  
  
  // videoBtn.setAttribute("src", CamOn);
  videoBtn.classList.add("videoBtn")
  micBtn.setAttribute("src", MicOn);
  micBtn.classList.add("videoBtn")
  
  videoBtn.addEventListener("click", () => {
    store.dispatch(setFrontCam(!store.getState().frontCam))
    // frontCamera( store.getState().frontCam)
    if(store.getState().frontCam){
      videoBtn.setAttribute("src", CamOf);
      camOff("off")
    } else {
      videoBtn.setAttribute("src",CamOn);
      camOff("on")
    }
  });
  micBtn.addEventListener("click", () =>{ 
    store.dispatch(setFrontMic(!store.getState().frontMic));
    if(store.getState().frontMic){
      micBtn.setAttribute("src", MicOf);
    } else {
      micBtn.setAttribute("src",MicOn);
    }
  
  });
  frontFooter.appendChild(videoBtn);
  frontFooter.appendChild(micBtn);
  videoContainerInner.appendChild(frontFooter);

  videoContainerInner.appendChild(videoElement)
  videosContainer.appendChild(videoContainerInner);
}
 const camOff =(val= "")=>{
  const userVideo= document.querySelector(".userVideo");
  const frontVideo =document.querySelector(".Frontvideo")
  if(val==="off"){
    let div= document.createElement("div");
     div.innerText="Camera off";
     userVideo.append(div)
     frontVideo.style.display="none"
  }
  else{
    userVideo.remove()
    GetFrontPageStreamInitFrontPage()
  }
                 
}
export const hideShow =(val= "")=>{
  const userVideo= document.querySelector(".userVideo");

  if(val==="no"){
    userVideo.innerHTML=`<div>Only Audio</div>`;
  }
  else{
    userVideo.remove()
    GetFrontPageStreamInitFrontPage()
  }
                 
}


///////////////////////////////////////////////////////////////////////////////////////////////
let localStream;

export const GetLocalPreviewAndInitRoomConnection = async (
  isRoomHost,
  identity,
  roomId = null, // because we are getting id from the server & joiiner can join it by passing the id.
  onlyAudio,
  hostId,
  userId
) => {
  await fetchTURNCredentials();

  // let history = useHistory();
  const constraints = onlyAudio ? onlyAudioConstraints : defaultConstraints;
 navigator.mediaDevices
    .getUserMedia(constraints)  
    .then((stream) => {
      console.log("successfuly received local stream");
      localStream = stream;
      showLocalVideoPreview(localStream);
      // dispatch an action to hide overlay
      store.dispatch(setShowOverlay2(false));
      store.dispatch(setStable(true));   

      
      isRoomHost
        ? wss.createNewRoom(identity, onlyAudio,hostId,userId)
        : wss.joinRoom(identity, roomId, onlyAudio, userId);
       
    })
    .catch((err) => {
     console.log(err)
     store.dispatch(setValid(false));
    });
   

};

let peers = {};
let streams = [];

const getConfiguration = () => {
  const turnIceServers = getTurnIceServers();
  console.log("Successfully get the turnIceServers");

  if (turnIceServers) {
    return {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
        ...turnIceServers,
      ],
    };
  } else {
    console.warn("Using only STUN server");
    return {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    };
  }
};

const messengerChannel = "messenger";

export const prepareNewPeerConnection = (connUserSocketId, isInitiator) => {
  const configuration = getConfiguration();

  peers[connUserSocketId] = new Peer({
    initiator: isInitiator,
    config: configuration,
    stream: localStream,
    channelName: messengerChannel,
  });

  peers[connUserSocketId].on("signal", (data) => {
    // webRTC offer, webRTC Answer (SDP informations), ice candidates

    const signalData = {
      signal: data,
      connUserSocketId: connUserSocketId,
    };

    wss.signalPeerData(signalData);
  });

  peers[connUserSocketId].on("stream", (stream) => {
    console.log("new stream came");

    addStream(stream, connUserSocketId);
    streams = [...streams, stream];
  });

  peers[connUserSocketId].on("data", (data) => {

    const messageData = JSON.parse(data);
    if(Object.keys(messageData).filter(item => item === "content").length !== 0) {
      appendNewMessage(messageData);
    }
    else switchBtnState(messageData);
  });
};

export const handleSignalingData = (data) => {
  //add signaling data to peer connection
  peers[data.connUserSocketId].signal(data.signal);
};

export const removePeerConnection = (data) => {
  const { socketId } = data;
  const videoContainer = document.getElementById(socketId);
  const videoEl = document.getElementById(`${socketId}-video`);

  if (videoContainer && videoEl) {
    const tracks = videoEl.srcObject.getTracks();

    tracks.forEach((t) => t.stop());

    videoEl.srcObject = null;
    videoContainer.removeChild(videoEl);

    videoContainer.parentNode.removeChild(videoContainer);

    if (peers[socketId]) {
      peers[socketId].destroy();
    }
    delete peers[socketId];
  }
};

////////////////////////////////// UI Videos //////////////////////////////////
const showLocalVideoPreview = (stream) => {
  const videosContainer = document.querySelector(".meetingVideoArea");
  const videoContainerInner = document.createElement("div");
  videoContainerInner.classList.add("userVideo");
  const videoElement = document.createElement("video");
  videoElement.autoplay = true;
  videoElement.muted = true;
  videoElement.srcObject = stream;

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };
  const labelB = document.createElement("div");
  labelB.classList.add("userVideoHeader");
  labelB.innerHTML=`<img src="../assets/icons/micUpper.svg" alt=""  />`
  
  labelB.classList.add("hostmuteIndicator")
  videoContainerInner.appendChild(videoElement);
  videoContainerInner.appendChild(labelB);

  if (store.getState().connectOnlyWithAudio) {
    videoContainerInner.appendChild(getAudioOnlyLabel());
    videoContainerInner.appendChild(getNameLabel(store.getState().identity));
  }else {
    videoContainerInner.appendChild(getNameLabel(store.getState().identity));
    // videoContainer.appendChild(getMicLabel(store.getState().mikeInfo))
  }

  videosContainer.appendChild(videoContainerInner);
};

const addStream = (stream, connUserSocketId) => {
  // display incoming stream
  const videosContainer = document.querySelector(".meetingVideoArea");
  // videosContainer.classList.add("meetingVideoArea");
  const videoContainerInner = document.createElement("div");
  videoContainerInner.id = connUserSocketId;

  videoContainerInner.classList.add("userVideo", "fullVideo");
  const videoElement = document.createElement("video");
  videoElement.autoplay = true;
  videoElement.srcObject = stream;
  videoElement.id = `${connUserSocketId}-video`;
  videoElement.classList.add("shared")

  videoElement.onloadedmetadata = () => {
    videoElement.play();
  };

  // videoContainerInner.addEventListener("click", () => {
  //   if (videoContainerInner.classList.contains("full_screen")) {
  //     videoContainerInner.classList.remove("full_screen");
  //   } else {
  //     videoContainerInner.classList.add("full_screen");
  //   }
  // });

  videoContainerInner.appendChild(videoElement);

  // check if other user connected only with audio
  const participants = store.getState().participants;

  const participant = participants.find((p) => p.socketId === connUserSocketId);
  console.log(participant);
  if (participant?.onlyAudio) {
    videoContainerInner.appendChild(getAudioOnlyLabel(participant.identity));
    videoContainerInner.appendChild(getMicLabel(store.getState().mikeInfo))
    videoContainerInner.appendChild(getNameLabel(participant.identity));
  } else {
    // videoContainerInner.style.position = "static";
    videoContainerInner.appendChild(getNameLabel(participant.identity));
    videoContainerInner.appendChild(getMicLabel(store.getState().mikeInfo))
    // videoContainerInner.appendChild(getZoomScreen())

  }

  videosContainer.appendChild(videoContainerInner);
};

const getAudioOnlyLabel = (identity = "") => {
  const labelContainer = document.createElement("div");
  labelContainer.classList.add("label_only_audio_container");

  const label = document.createElement("p");
  label.classList.add("label_only_audio_text");
  // label.innerHTML = `Only audio ${identity}`;
  label.innerHTML = `Only audio`;

  labelContainer.appendChild(label);
  return labelContainer;
};

//Show the name tag on the video element
const getNameLabel = (identity = "") => {
  const label = document.createElement("div");
  label.classList.add("userVideoFooter")
  const labelIn = document.createElement("p");
  labelIn.classList.add("videoUsername")
  labelIn.innerHTML = `${identity}`;
  label.appendChild(labelIn);
  return label;
};

//show mic on the video element
const getMicLabel = (mikeInfo) => {
  console.log("inside of the mic lable",mikeInfo)
   if(mikeInfo){
     const label = document.createElement("div");
     label.classList.add("userVideoHeader","muteIndicator");
    //  label.innerHTML = `<img src="../assets/icons/micIcon.svg" alt="" class="videoUsername" />`
    label.innerHTML = `<img src="../assets/icons/micUpper.svg" alt=""  />`
   return label;
   }
   const label = document.createElement("div");
   label.classList.add("userVideoHeader","muteIndicator");
  //  label.innerHTML = `<img src="../assets/icons/mic on.svg" alt=""  />`
 return label;
 };

//  const getZoomScreen = () => {
//   const label = document.createElement("div");
//   label.classList.add("userVideoZoom");
//   label.innerHTML = `<dv class="fullIconWrap"><img src="../assets/icons/fullScreen.png" alt=""  /></dv>`
// return label;
//  };
////////////////////////////////// Buttons logic //////////////////////////////////

export const toggleMic = (isMuted) => {
  localStream.getAudioTracks()[0].enabled = isMuted ? true : false;
};

export const toggleCamera = (isDisabled) => {
  localStream.getVideoTracks()[0].enabled = isDisabled ? true : false;
};

export const toggleScreenShare = (
  isScreenSharingActive,
  screenSharingStream = null
) => {
  if (isScreenSharingActive) {
    switchVideoTracks(localStream);
  } else {
    switchVideoTracks(screenSharingStream);
  }
};

const switchVideoTracks = (stream) => {
  for (let socket_id in peers) {
    for (let index in peers[socket_id].streams[0].getTracks()) {
      for (let index2 in stream.getTracks()) {
        if (
          peers[socket_id].streams[0].getTracks()[index].kind ===
          stream.getTracks()[index2].kind
        ) {
          peers[socket_id].replaceTrack(
            peers[socket_id].streams[0].getTracks()[index],
            stream.getTracks()[index2],
            peers[socket_id].streams[0]
          );
          break;
        }
      }
    }
  }
};

////////////////////////////////// Messages /////////////////////////////////////
const appendNewMessage = (messageData) => {
  const messages = store.getState().messages;
  store.dispatch(setMessages([...messages, messageData]));
};

export const sendMessageUsingDataChannel = (messageContent) => {
  // append this message locally
  const identity = store.getState().identity;
  const localMessageData = {
    content: messageContent,
    identity,
    messageCreatedByMe: true,
  };

  appendNewMessage(localMessageData);

  const messageData = {
    content: messageContent,
    identity,
  };

  const stringifiedMessageData = JSON.stringify(messageData);
  for (let socketId in peers) {
    peers[socketId].send(stringifiedMessageData);
  }
};

////////////////////////////////////SEND THE BUTTON STATE ON OTHER PEER//////////////////

const switchBtnState = (messageData) => {
  // const setBtn = store.getState().shareBtn;
  console.log(messageData.Mute,"Data is come from the data-channel");
  // store.dispatch(setShareBtn([messageData]));

  if(Object.keys(messageData).filter(item => item === "Mute").length !== 0) {
    store.dispatch(setMikeInfo(messageData.Mute))
    console.log("Mice is Mute",messageData.Mute);
  }else{
    store.dispatch(setShareBtn(messageData))
    console.log("share btn state is change");
  }
};


export const sendButtonStateUsingDataChannel = (BtnState) => {
  const identity = store.getState().identity;
  const messageData = {
    identity,
    Buttons:BtnState
  };

  const stringifiedMessageData = JSON.stringify(messageData);
  for (let socketId in peers) {
    peers[socketId].send(stringifiedMessageData);
  }

}

export const sendMikeState = (BtnState) =>{
  const identity = store.getState().identity;
  const messageData = {
    identity,
    Mute:BtnState
  };
  const stringifiedMessageData = JSON.stringify(messageData);
  for (let socketId in peers) {
    peers[socketId].send(stringifiedMessageData);
  }

}